import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
	Grid,
	Typography,
	makeStyles,
	Card,
	CardHeader,
	CardContent,
	useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		borderRadius: '20px',
		padding: 0,
		margin: `1rem`,
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	ctaLink: {
		marginBottom: '.5rem',
		'&:hover': {
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
	resourceBlurb: {
		fontSize: '1.125rem',
		lineHeight: 1.4,
	},
}));

export const Resource = ({ resource }) => {
	const classes = useStyles();
	const sm = useMediaQuery('(max-width: 715px)');
	return (
		<Grid item xs={12} sm={7} md={5} lg={4} style={{ zIndex: 100 }}>
			<a
				href={resource.ctaLink}
				target='_blank'
				rel='noreferrer'
				style={{
					textDecoration: 'none',
				}}>
				<Card elevation={0} className={classes.root}>
					<CardHeader
						style={{
							padding: 0,
						}}
						subheader={
							<GatsbyImage
								image={resource.image?.asset?.gatsbyImageData}
								style={{ height: '12rem', backgroundSize: 'cover' }}
								alt='resource image'
							/>
						}></CardHeader>
					<CardContent
						style={{
							height: sm ? null : '14rem',
							padding: '16px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}>
						<Grid
							style={{ height: sm ? null : '100%', margin: '0' }}
							container
							direction='column'
							justifyContent='flex-start'
							alignItems='flex-start'>
							<Typography
								variant='h6'
								style={{ lineHeight: '1', marginBottom: '10px' }}>
								{resource.title}
							</Typography>

							<Typography variant='body1' className={classes.resourceBlurb}>
								{resource.blurb}
							</Typography>
						</Grid>
						<Grid container>
							<Typography
								variant='body2'
								className={classes.ctaLink}
								style={{
									color: '#002D5C',
									fontWeight: 600,
									fontSize: '1.25rem',
									marginTop: sm && '1rem',
								}}>
								{resource.ctaText}
								<span className={classes.arrow}>→</span>
							</Typography>
						</Grid>
					</CardContent>
				</Card>
			</a>
		</Grid>
	);
};
