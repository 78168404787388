import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '16px 16px 20px 20px',
		minHeight: '78px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		// maxWidth: '289px',
		position: 'relative',
		width: '100%',
		// paddingTop: '2.75rem',
		// [theme.breakpoints.down('xs')]: {
		// 	maxWidth: '100% !important',
		// 	marginLeft: '0px',
		// 	marginRight: '0px',
		// 	width: '100%',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	maxWidth: '263px',
		// },
		[theme.breakpoints.down('md')]: {
			maxWidth: '500px',
			minWidth: '435px',
			width: '100%',
			maxHeight: '250px',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '355px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '355px',
			height: '210px',
		},
		transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontSize: '1.25rem',
		fontWeight: 600,
		textAlign: 'left',
		color: '#183B56',
		marginLeft: '.5rem',
		lineHeight: 1.3,
		[theme.breakpoints.down('xs')]: {
			marginLeft: '1rem',
		},
	},
	header: {
		fontSize: '1.625rem',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.2rem',
		},
	},
	pos: {
		textAlign: 'left',
		lineHeight: '32px',
		lineHeight: 1.3,
		paddingLeft: '10px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.2rem',
		},
	},
	discount: {
		borderRadius: '10px 10px 0px 0px',
		minHeight: '60px',
		width: '250px',
		// [theme.breakpoints.down('md')]: {
		// 	maxWidth: '35%',
		// },
	},
	cardBody: {
		marginTop: '16px',
		minHeight: '71px',
		// [theme.breakpoints.down('md')]: {
		// 	minHeight: '90px',
		// },
	},
}));

export const HighlightCard = ({ card }) => {
	const classes = useStyles();
	const { title, header, _rawContent, icon, accentColor } = card;
	return (
		<Grid
			item
			xs={12}
			md={6}
			lg={4}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Card className={classes.root} elevation={0} style={{ zIndex: 100 }}>
				<Grid
					container
					alignItems='center'
					justifyContent='flex-start'
					style={{
						top: 0,
						borderTop: `8px solid ${card.accentColor.hexValue ?? '#002D5C'}`,
						background: card.accentColor.hexValue,
						// textAlign: 'center',
						// height: '100%',
					}}>
					{/* <Typography
						variant='h4'
						style={{ color: 'white', paddingBottom: '12px' }}>
						{title}
					</Typography> */}
				</Grid>
				<CardContent
					style={{
						minHeight: '175px',
						display: 'flex',
						flexDirection: 'column',
						padding: '14px',
						alignItems: 'space-evenly',
						justifyContent: 'space-between',
					}}>
					<Grid
						item
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'
						style={{ flexWrap: 'nowrap' }}>
						<FontAwesomeIcon
							icon={['fas', icon]}
							style={{
								color: accentColor.hexValue ?? '#002D5C',
								height: '60px',
								width: '60px',
								padding: '10px',
								textAlign: 'center',
							}}
						/>
						<Typography variant='h4' color='primary' className={classes.header}>
							{header}
						</Typography>
					</Grid>
					<Grid
						container
						item
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-start'
						className={classes.cardBody}>
						<PortableText
							content={_rawContent}
							serializers={{
								normal: ({ children }) => (
									<Typography variant='body1' className={classes.pos}>
										{children}
									</Typography>
								),
							}}
						/>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
