import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';

import { HighlightCard } from './HighlightCard';
import { CompareCard } from './CompareCard';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    paddingTop: '6rem', 
    paddingBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
}));

export const CompareBody = ({ compare }) => {
	const {
		highlightCardHeader,
		highlightCards,
		compareCardHeader,
		compareCards,
	} = compare;
  const classes = useStyles();
  const md = useMediaQuery('(max-width: 960px)');
	return (
		<>
			<div style={{ background: '#FFF', marginBottom: md ? '-28rem' : '-22rem',  }}>
				<Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
					<Grid
						container
						className={classes.headerTitle}
						xs={12} md={8}>
						<Typography variant='h2' color='primary' style={{ zIndex: 100, textAlign: md ? 'left' : 'center' }}>
							{highlightCardHeader}
						</Typography>
					</Grid>
					<Grid
						container
						spacing={2}
						style={{ paddingBottom: '4rem' }}
						justifyContent='center'>
						{highlightCards.map((card, index) => (
							<HighlightCard card={card} key={index} />
						))}
					</Grid>
				</Container>
			</div>
			<WaveDownSVG shadow fill='#FFF' />
			<div style={{ background: '#EFF2F7', paddingTop: md ? '27rem' :'24rem' }}>
				<Container>
					<Grid
						container
						style={{ paddingBottom: '4rem' }}
						justifyContent='center'>
						<Typography variant='h2' color='primary'>
							{compareCardHeader}
						</Typography>
					</Grid>
					<Grid container style={{ paddingBottom: '4rem' }}>
						{compareCards.map((card, index) => (
							<CompareCard card={card} index={index} />
						))}
					</Grid>
				</Container>
			</div>
		</>
	);
};
